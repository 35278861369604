import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';


function App() {
  const options = {
    headers: {
      'Authorization': 'arusolution 50c80dfd0ff61e16847671e107402a8cc48428cd',
      'Access-Control-Allow-Origin':'*',
    },
  };
  
  const response = fetch('https://swarfarm.com/api/v2/monsters/', options);
  console.log(response);
  return (
    <div className='bg-cyan-900 '>
      <div className="">
        <header className="container mx-auto">
          xxxปปป
        </header>
        <section className="container mx-auto">
        <div className="grid grid-cols-3 gap-4">
            <div className="..."></div>
            <div className="col-span-2 ...">04</div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
